function CalendarDay({data,active,handleClick,index}) {
    return (
        <>
            <div className='calendar-day cursor-pointer' onClick={() => handleClick(index)}>
                <div className={active ? 'rounded-[10px] p-2 bg-custom-red' : 'rounded-[10px] p-2 bg-custom-black3'}>
                    <p className='text-white text-center  text-sm mb-2'>
                        {data.day}
                    </p>
                    <p className='text-white text-center text-sm'>
                        {data.date}
                    </p>
                </div>
            </div>
        </>
    )
}
export  default  CalendarDay;