import {Select, TextInput, ToggleSwitch} from "flowbite-react";
import {ReactComponent as searchIcon} from '../assets/img/svg/search.svg';
import arrowRightIcon from "../assets/img/svg/arrow-right.svg";
import filterSearchIcon from "../assets/img/svg/filter-search.svg";
import smileysIcon from "../assets/img/svg/smileys.svg";
import mapIcon from "../assets/img/svg/map.svg";
import starIcon from "../assets/img/svg/star.svg";
import calenderSearchIcon from "../assets/img/svg/calendar-search.svg";
import searchStatusIcon from "../assets/img/svg/search-status.svg";
import filterIcon from "../assets/img/svg/filter2.svg";

import RangeSlider from 'react-range-slider-input';
import {useEffect, useState} from "react";
import axios from "axios";
import {baseUrl} from "../helper";
import {useLocation, useNavigate, useParams} from "react-router-dom";
function FilterPage() {
    const location  = useLocation();
    const params = useParams();
    const navigate = useNavigate();
    const [isFilter,setIsFilter] = useState(false)
    const [search,setSearch] = useState('')
    const [movieType,setMovieType] = useState(0)
    const [genreId,setGenreId] = useState(null)
    const [countryId,setCountryId] = useState(null)
    const [imdb,setImdb] = useState([0,10])
    const [year,setYear] = useState([1900,new Date().getFullYear()])
    const [age,setAge] = useState([1,100])
    const [dubbed, setDubbed] = useState(false);
    const [isHot, setIsHot] = useState(false);
    const [isSoon, setIsSoon] = useState(false);
    const [genres,setGenres] = useState([]);
    const [countries,setCountries] = useState([]);



    useEffect(() => {
        if(location.pathname.includes('filter')){
            setIsFilter(true);
            if (params.movieType !== undefined && (params.movieType == 1 || params.movieType == 2)) {
                setMovieType(parseInt(params.movieType)); // Ensure it is set as a number
            }
        }
    }, [params.movieType]); // Dependency array to monitor changes

    useEffect(() => {

        axios.get(`${baseUrl}/api/v1/genre`)
            .then(res => {
                setGenres(res.data.data)
            })
            .catch(err => {
                console.log('get genre ====' + err.toString())
            })
    }, [])


    useEffect(() => {
        axios.get(`${baseUrl}/api/v1/country`)
            .then(res => {
                setCountries(res.data.data)
            })
            .catch(err => {
                console.log('get country ====' + err.toString())
            })
    }, [])


    function handleSubmit() {

        if(isFilter){
            navigate(`/?search=${encodeURIComponent(search)}&movie_type=${encodeURIComponent(movieType)}&genre_id=${encodeURIComponent(genreId)}&country_id=${encodeURIComponent(countryId)}&imdb=${encodeURIComponent(imdb.join(','))}&year=${encodeURIComponent(year.join(','))}&age=${encodeURIComponent(age.join(','))}&dubbed=${encodeURIComponent(dubbed ? '1' : '0')}&is_hot=${encodeURIComponent(isHot ? '1' : '0')}&is_soon=${encodeURIComponent(isSoon ? '1' : '0')}`);
        }else{
            navigate(`/result?search=${encodeURIComponent(search)}&movie_type=${encodeURIComponent(movieType)}&genre_id=${encodeURIComponent(genreId)}&country_id=${encodeURIComponent(countryId)}&imdb=${encodeURIComponent(imdb.join(','))}&year=${encodeURIComponent(year.join(','))}&age=${encodeURIComponent(age.join(','))}&dubbed=${encodeURIComponent(dubbed ? '1' : '0')}&is_hot=${encodeURIComponent(isHot ? '1' : '0')}&is_soon=${encodeURIComponent(isSoon ? '1' : '0')}`);
        }
    }

    return (
        <>

            <section className='header fixed right-0 top-0 left-0 z-20 p-3  bg-[#1D2025]'>
                <div className='flex items-center'>
                            <a onClick={() => navigate(-1)} className='me-2' href='#'>
                            <img src={arrowRightIcon} alt='' />
                        </a>
                        <p className='text-lg text-white'>
                            {isFilter ? "فیلتر" : "جستجو پیشرفته"}
                        </p>
                </div>
            </section>
            <div className='px-3 mt-[70px]'>
                <section>
                    <form>
                        <div className='input-group mb-5'>
                            <TextInput onChange={(e) => setSearch(e.target.value)} className='custom-input-icon' id="search" type="text" icon={searchIcon}
                                       placeholder="جستجو عنوان ..." />
                        </div>

                        {!isFilter &&
                            <div className='input-group mb-5'>
                                <div className='flex justify-between items-center'>
                                    <div className='flex items-center'>
                                        <img className='me-1.5' src={filterSearchIcon} alt='' />
                                        <p className='text-sm text-white'>
                                            نوع
                                        </p>
                                    </div>

                                    <div className='min-w-60'>
                                        <Select onChange={(e) => setMovieType(e.target.value)}  id="movie_type" name='movie_type' className='custom-select'>
                                            <option value='0'>نوع</option>
                                            <option value='1'>فیلم</option>
                                            <option value='2'>سریال</option>
                                        </Select>
                                    </div>
                                </div>
                            </div>
                        }

                        <div className='input-group mb-5'>
                            <div className='flex justify-between items-center'>
                                <div className='flex items-center'>
                                    <img className='me-1.5' src={smileysIcon} alt='' />
                                    <p className='text-sm text-white'>
                                        ژانر
                                    </p>
                                </div>

                                <div className='min-w-60'>
                                    <Select onChange={(e) => setGenreId(e.target.value)}   id="genre_id" name='genre_id' className='custom-select'>
                                        <option value='null'>ژانر</option>
                                        {genres.map(genre =>  <option value={genre.id} key={genre.id}>{genre.title}</option>)}
                                    </Select>
                                </div>
                            </div>
                        </div>

                        <div className='input-group mb-5'>
                            <div className='flex justify-between items-center'>
                                <div className='flex items-center'>
                                    <img className='me-1.5' src={mapIcon} alt='' />
                                    <p className='text-sm text-white'>
                                        کشور
                                    </p>
                                </div>

                                <div className='min-w-60'>
                                    <Select onChange={(e) => setCountryId(e.target.value)}  id="country_id" name="country_id"   className='custom-select'>
                                        <option value='null'>کشور</option>
                                        {countries.map(country =>  <option value={country.id} key={country.id}>{country.title}</option>)}
                                    </Select>
                                </div>
                            </div>
                        </div>

                        <div className='input-group mb-5'>
                            <div className='flex justify-between items-center'>
                                <div className='flex items-center'>
                                    <img className='me-1.5' src={starIcon} alt='' />
                                    <p className='text-sm text-white'>
                                        امتیاز
                                    </p>
                                </div>

                                <div className='min-w-60 h-0'>
                                    <RangeSlider onInput={(value) => setImdb(value)} defaultValue={[1,10]} min='1' max='10'  step='0.1' />
                                    <p className='text-xs text-white text-center mt-3'>{imdb[0] + "-" + imdb[1]}</p>
                                </div>
                            </div>
                        </div>
                        <div className='input-group mb-10'>
                            <div className='flex justify-between items-center'>
                                <div className='flex items-center'>
                                    <img className='me-1.5' src={calenderSearchIcon} alt='' />
                                    <p className='text-sm text-white'>
                                        سال ساخت
                                    </p>
                                </div>
                                <div className='min-w-60 h-0'>
                                    <RangeSlider onInput={(value) => setYear(value)} defaultValue={[1900,new Date().getFullYear()]} min='1900' max='2024'  step='1' />
                                    <p className='text-xs text-white text-center mt-3'>{year[0] + "-" + year[1]}</p>
                                </div>
                            </div>
                        </div>
                        <div className='input-group mb-10'>
                            <div className='flex justify-between items-center'>
                                <div className='flex items-center'>
                                    <img className='me-1.5' src={calenderSearchIcon} alt='' />
                                    <p className='text-sm text-white'>
                                        رده سنی
                                    </p>
                                </div>
                                <div className='min-w-60 h-0'>
                                    <RangeSlider onInput={(value) => setAge(value)} defaultValue={[1,100]} min='1' max='100'  step='1' />
                                    <p className='text-xs text-white text-center mt-3'>{age[0] + "-" + age[1]}</p>
                                </div>
                            </div>
                        </div>
                        <div className='input-group mb-10'>
                            <div className='flex items-center'>
                                    <p className='text-white text-base me-5 w-[85px]'>
                                    دوبله فارسی
                                </p>
                                <ToggleSwitch  color='yellow'  className='custom-switch' checked={dubbed} label="" onChange={setDubbed} />
                            </div>
                        </div>
                        <div className='input-group mb-10'>
                            <div className='flex items-center'>
                                <p className='text-white text-base me-5 w-[85px]'>
                                    داغ
                                </p>
                                <ToggleSwitch color='yellow' className='custom-switch' checked={isHot} label="" onChange={setIsHot} />
                            </div>
                        </div>
                        <div className='input-group mb-10'>
                            <div className='flex items-center'>
                                    <p className='text-white text-base me-5 w-[85px]'>
                                     بزودی
                                </p>
                                <ToggleSwitch  color='yellow'  className='custom-switch' checked={isSoon} label="" onChange={setIsSoon} />
                            </div>
                        </div>

                        <button onClick={() => handleSubmit()} className='flex justify-center font font-medium min-w-[230px]  text-lg items-center bg-custom-red rounded-[10px] w-[50%] mx-auto h-[50px] text-center text-white mb-10'>

                            {isFilter ?<><img className='me-4' src={filterIcon} alt='' /><p>فیلتر</p></>  : <><img className='me-4' src={searchStatusIcon} alt='' /><p>جستجو</p></>}
                        </button>

                    </form>
                </section>

            </div>

        </>
    )
}

export default FilterPage;