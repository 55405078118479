import fireIcon from '../../assets/img/fire.png';
import imdbIcon from '../../assets/img/imdb.png';
import instagramIcon from '../../assets/img/instagram.png';
import telegramIcon from '../../assets/img/telegram.png';
import notificationIcon from "../../assets/img/svg/notification.svg";
import {Link} from "react-router-dom";

function MoreTab() {

    return (
        <>
            <section className='header fixed right-2 top-0 left-2 z-20 py-2  bg-custom-black'>
                <div className='flex justify-between'>
                    <div className='relative right-2'>
                        <h1 className='text-[#FFB900] font-medium text-[18px] '>Mobo Movie</h1>
                        <div className=' header-title-shadow'/>
                    </div>
                    <div>
                        <Link to='/notification' className='flex relative z-30 justify-center items-center text-sm text-white bg-custom-red w-[110px] h-[40px] rounded-tr-[50px] rounded-br-[50px]' href='#'>
                            اطلاع رسانی
                               <div>
                                   <img className='ms-0.5' src={notificationIcon} alt='notification'/>
                                   {/*<div*/}
                                   {/*    className='bg-custom-yellow w-[12px] h-[12px] text-center text-white rounded-full text-[8px] absolute mt-[-22px]'>2*/}
                                   {/*</div>*/}
                               </div>

                        </Link>
                        <div className='bg-custom-red mt-[-40px] blur-lg w-[110px] h-[40px] rounded-tr-[50px] rounded-br-[50px]'></div>
                    </div>
                </div>
            </section>
                <section className='more-btn mt-20 mb-10'>
                    <Link to='/result?movie_type=1&order_type=3'  className='more-yellow-btn mb-3'>
                        <span>
                            ۲۵۰ فیلم برتر
                        </span>
                        <img src={imdbIcon}  className='w-[39px] h-[20px]' alt=''/>
                    </Link>
                    <Link  to='/result?movie_type=2&order_type=3' className='more-yellow-btn mb-3'>
                        <span>
                           ۲۵۰ سریال برتر
                        </span>
                        <img src={imdbIcon}  className='w-[39px] h-[20px]' alt=''/>
                    </Link>
                    <Link  to='/result?movie_type=1&order_type=1&is_hot=1' className='more-red-btn mb-3'>
                        <span>
                         لیست فیلم‌های داغ
                        </span>
                        <img src={fireIcon}  className='w-[29px] h-[29px]' alt=''/>
                    </Link>
                    <Link to='/result?movie_type=2&order_type=1&is_hot=1' className='more-red-btn mb-3'>
                        <span>
                        لیست سریال‌های داغ
                        </span>
                        <img src={fireIcon}  className='w-[29px] h-[29px]' alt=''/>
                    </Link>
            </section>


            <section className='descirption'>
                <h3 className='text-3xl text-white font-bold text-center'>درباره ما</h3>
                <p className='w-75 mx-auto my-10 text-center  text-white'>
                    لورم ایپسوم متن ساختگی با تولید سادگی نامفهوم از صنعت چاپ، و با استفاده از طراحان گرافیک است، چاپگرها و متون بلکه روزنامه و مجله در ستون و سطرآنچنان که لازم است
                </p>

                <div className='flex justify-center'>
                    <a href='#' className='me-5'>
                        <img className='w-[80px] h-[80px]' src={instagramIcon} alt='instagarm' />
                    </a>
                    <a href='#'>
                        <img className='w-[80px] h-[80px]'  src={telegramIcon} alt='telegram' />
                    </a>
                </div>
            </section>
        </>
    );
}

export default MoreTab;