import axios from "axios";

export const baseUrl = "https://api.ssfd.ir";

export async function getMovie(page = 1, movie_type = 0, search = "", is_hot = 0, is_soon = 0, genre_id =  null, country_id = null, actor_id = null, dubbed = 0, bookmark_list = null,min_age = null,max_age = null,min_imdb=null,max_imdb=null,min_year=null,max_year=null,order_type = "0") {
    try {
        const res = await axios.post(`${baseUrl}/api/v1/movie`, {
            page,
            movie_type,
            search,
            is_hot,
            is_soon,
            genre_id,
            country_id,
            actor_id,
            dubbed,
            bookmark_list,
            order_type,
            min_age,
            max_age,
            min_imdb,
            max_imdb,
            min_year,
            max_year,
        });
        return res.data;
    } catch (err) {
        console.log('error getMovie ==== ' + err.toString());
        return null;
    }
}

// گرفتن لیست بوکمارک‌ها
export function getBookMarkList() {
    return JSON.parse(localStorage.getItem('bookmarks')) || [];
}

export function addToBookMarkList(id) {
    let bookmarkList = JSON.parse(localStorage.getItem('bookmarks')) || []; // مقداردهی اولیه در صورت null بودن
    if (!bookmarkList.includes(id)) { // جلوگیری از افزودن تکراری
        bookmarkList.push(id);
        localStorage.setItem('bookmarks', JSON.stringify(bookmarkList));
    }
}


// حذف آیتم از لیست بوکمارک‌ها
export function removeFromBookMarkList(id) {
    let bookmarkList = JSON.parse(localStorage.getItem('bookmarks')) || [];
    bookmarkList = bookmarkList.filter(item => item != id); // حذف آیتم مورد نظر
    localStorage.setItem('bookmarks', JSON.stringify(bookmarkList));
}

// حذف همه بوکمارک‌ها
export function clearBookMarkList() {
    localStorage.removeItem('bookmarks');
}
export  function  isBookMark(id){
    let bookmarkList = JSON.parse(localStorage.getItem('bookmarks')) || []; // مقداردهی اولیه در صورت null بودن
    return bookmarkList.includes(id)
}





