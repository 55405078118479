import {ADD_BOOKMARK_DATA, REPLACE_BOOKMARK_DATA} from "./bookmarkActionType";

const initialState = [];


const bookmarkReducer = (state = initialState, action) => {
    switch (action.type){
        case  REPLACE_BOOKMARK_DATA :
            return  action.payload;
        case  ADD_BOOKMARK_DATA :
            return [...state,...action.payload];
        default:
            return  state;
    }
}

export  default  bookmarkReducer;