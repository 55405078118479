function Notification({notification}) {
    return (
        <>
            <div className='notificaiton mb-5'>
                <div className='rounded-[10px]  bg-custom-black3 p-8'>
                    <h3 className='font-bold text-white mb-3 text-xl'>
                        {notification.title}
                    </h3>
                    <p className='text-white text-sm text-justify'>
                        {notification.description}
                    </p>
                </div>
            </div>
        </>
    )
}
export  default  Notification;