import arrowRightIcon from "../assets/img/svg/arrow-right.svg";
import {baseUrl} from "../helper";
import CalendarDay from "../components/CalendarDay";
import CalendarData from "../components/CalendarData";
import {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";

function CalendarPage() {
    const [calendars, setCalendars] = useState([]);
    const [activeDay, setActiveDay] = useState(null);
    const navigate =useNavigate();

    useEffect(() => {
        axios.get(`${baseUrl}/api/v1/movieCalendar`)
            .then(res => {
                setCalendars(res.data.data)
            })
            .catch(err => {
                console.log('get calendars ====' + err.toString())
            })
    }, [])

    function handleClick(index) {
        setActiveDay(index);
    }
    return (
        <>
            <section className='header fixed right-0 top-0 left-0 z-20 p-3  bg-[#1D2025]'>
                <div className='flex items-center'>
                    <a onClick={() => navigate(-1)} className='me-2' href='#'>
                        <img src={arrowRightIcon} alt=''/>
                    </a>
                    <p className='text-lg text-white'>
                        زمان پخش فیلم و سریال
                    </p>
                </div>
            </section>

            <div className='px-3 mt-[70px] '>
                <section className='calendar-day mb-10'>
                    <div className="grid  grid-cols-3  gap-2">
                        {calendars && calendars.map((movieCalendar, index, array) => {
                            if (activeDay === null && movieCalendar.isCurrentDay)
                                setActiveDay(index)
                            return (
                                <CalendarDay index={index} handleClick={handleClick} key={index} active={activeDay === index} data={movieCalendar}/>
                            )
                        })}
                    </div>
                </section>

                <section className='calendar-data mb-10'>
                    {activeDay !== null && calendars[activeDay].data.map(data => <CalendarData data={data}/>)}
                </section>

            </div>


        </>
    );
}

export default CalendarPage;