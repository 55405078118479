import timeIcon from "../assets/img/svg/time.svg";


function CalendarData({data}) {
    return (
        <>
            <div className='calendar-data mb-5'>
                    <div className='rounded-[10px]  bg-custom-black3 p-2'>
                        <div className='flex justify-between items-center'>
                            <div className='w-[75%] text-white text-sm'>
                                <p className='mb-3'>{data.movie.title}</p>
                                <div className='flex justify-between'>
                                    <p>{data.date}</p>
                                    <div className='flex me-1'>
                                        {data.time}
                                        <img src={timeIcon} className='ms-1' alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className='w-[25%]'>
                                <img src={data.movie.picture} alt='' className='rounded-[5px] w-[100px] max-w-[90%] d-block ms-auto'/>
                            </div>
                        </div>
                    </div>
            </div>
        </>
    )
}
export  default  CalendarData;