import {addToBookMarkList, baseUrl, getMovie, isBookMark, removeFromBookMarkList} from "../helper";
import {Accordion, Modal} from "flowbite-react";
import arrowRightIcon from "../assets/img/svg/arrow-right.svg";
import heartIcon from "../assets/img/svg/heart.svg";
import heartRedIcon from "../assets/img/svg/heart-red.svg";
import playIcon from "../assets/img/svg/play.svg";
import playIcon2 from "../assets/img/svg/play2.svg";
import ageIcon from "../assets/img/svg/age.svg";
import clockIcon from "../assets/img/svg/clock.svg";
import smileysIcon from "../assets/img/svg/smileys.svg";
import mapIcon from "../assets/img/svg/map.svg";
import starIcon from "../assets/img/svg/star.svg";
import calenderIcon from "../assets/img/svg/calendar.svg";
import videoIcon from "../assets/img/svg/video.svg";
import serialIcon from "../assets/img/svg/serial.svg";
import playIcon3 from "../assets/img/svg/play3.svg";
import {Swiper, SwiperSlide} from "swiper/react";
import Movie from "../components/Movie";
import Actor from "../components/Actor";
import {Link, useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";
import ReactPlayer from "react-player";
import closeIcon from "../assets/img/svg/close-circle.svg";

function MoviePage() {

    const {movieId} = useParams();
    const navigate = useNavigate();
    const [movie, setMovie] = useState(null);
    const [bookmark, setBookmark] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [movies, setMovies] = useState([]);


    async function getMovies() {
        const data = await getMovie(1, 2);
        setMovies(data.data.movies);
    }


    useEffect(() => {
        if (!isNaN(movieId)) {
            axios.get(`${baseUrl}/api/v1/movie/${movieId}`)
                .then(res => {
                    setMovie(res.data.data)
                    setBookmark(isBookMark(movieId));
                })
                .catch(err => {
                    console.log('get movie on show movie page ====' + err.toString())
                    navigate('/');
                })
        } else {
            navigate('/');
        }

        getMovies();

    }, [])


    function handleBookmark() {
        if (!isBookMark(movieId)) {
            addToBookMarkList(movieId);
            setBookmark(true);
        } else {
            removeFromBookMarkList(movieId);
            setBookmark(false);
        }
    }

    function handleOpen() {
        setOpenModal(true)
    }

    const customTheme =
        {
            "root": {
                "base": "",
            },
        }
    const customThemeModal =
        {
            "content": {
                "inner": "relative flex max-h-[90dvh] flex-col rounded-lg  shadow bg-transparent transition-all duration-1000 ease-in-out  order-modal"
            },
        }

    return (
        <>

            {movie &&
                <>
                    <Modal position='center' theme={customThemeModal} show={openModal}
                           onClose={() => setOpenModal(false)}>
                        <Modal.Body>
                            <div className='font-iransans'>
                                <div className='flex justify-between items-center mb-2'>
                                    <p className='text-xl text-bold text-white'>

                                    </p>
                                    <div>
                                        <a className='custom-delete-border-shadow' href='#'
                                           onClick={() => setOpenModal(false)}>
                                            <img src={closeIcon} alt=''/>
                                        </a>
                                    </div>
                                </div>
                                <ReactPlayer width='100%' className='mx-auto w-full' controls={true}
                                             url={movie.trailer}/>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <section className='header'>
                        <div className="movie-header">
                            <div className='flex justify-between items-center px-3'>
                                <a onClick={() => navigate(-1)} className='me-2 cursor-pointer'>
                                    <img src={arrowRightIcon} alt=''/>
                                </a>
                                <a onClick={() => handleBookmark()} className='me-2 cursor-pointer'>
                                    <img src={bookmark ? heartRedIcon : heartIcon} alt=''/>
                                </a>
                            </div>
                            <div className='flex justify-end mt-12'>
                                <div className='w-[50%]'>
                                    <h3 className='text-white overflow-nowrap dir-ltr mb-2  font-bold text-md text-center'>
                                        {movie.title}
                                    </h3>
                                    <h3 className='text-white overflow-nowrap  text-sm font-medium text-center'>
                                        {movie.title_fa}
                                    </h3>
                                </div>
                            </div>
                        </div>
                        <div className='movie-header-bg' style={{backgroundImage: `url('${movie.picture}')`}}>
                        </div>
                    </section>

                    <section className='content'>


                        <div className='bg-custom-black3 mt-[-100px] rounded-tr-[15px] rounded-tl-[15px]  px-3'>

                            <div className='flex justify-between items-end relative top-[-110px]'>
                                <div className='relative'>
                                    <img src={movie.picture} className='w-[143px] rounded-[5px]' alt=''/>
                                    {movie.trailer &&
                                        <a onClick={() => handleOpen()}
                                           className='absolute bottom-2 left-4 cursor-pointer'>
                                            <img src={playIcon} alt=''/>
                                        </a>
                                    }

                                </div>
                                <div className='w-[50%]'>
                                    <div className='flex justify-center items-center mb-3'>
                                        <div className='relative'>
                                            <img src={ageIcon} alt=''/>
                                            <span
                                                className='text-sm  font-bold text-custom-red absolute bottom-0.5 text-center left-2'>{movie.age_type}</span>
                                        </div>
                                        <p className='ms-1 text-white text-sm '>
                                            رده سنی
                                        </p>
                                    </div>
                                    <div className='flex justify-center items-center'>
                                        <div className='w-[36px] h-[36px] flex justify-center items-center'>
                                            <img src={clockIcon} alt='' className='w-[24px] h-[24px]'/>
                                        </div>
                                        <p className='ms-1 text-white text-sm '>
                                            زمان:
                                            <span className='ms-1'>
                                        {movie.movie_time}
                                    </span>
                                        </p>
                                    </div>
                                </div>
                            </div>


                            <div className="grid  grid-cols-2  gap-3 mt-[-60px]">
                                <div className='flex items-center text-white text-base font-normal mb-3'>
                                    <img src={smileysIcon} alt='' className='me-1'/>
                                    <span className='me-1'>
                            ژانر:
                            </span>
                                    <span className='overflow-nowrap'>
                                {movie.genres.map(genre => genre.title).join(' - ')}
                            </span>
                                </div>

                                <div className='flex items-center text-white text-base font-normal mb-3'>
                                    <img src={mapIcon} alt='' className='me-1'/>
                                    <span className='me-1'>
                            کشور:
                            </span>
                                    <span className='overflow-nowrap'>
                                {movie.countries.map(country => country.title).join(' - ')}
                            </span>
                                </div>

                                <div className='flex items-center text-white text-base font-normal mb-3'>
                                    <img src={calenderIcon} alt='' className='me-1'/>
                                    <span className='me-1'>
                            سال ساخت:
                            </span>
                                    <span className='overflow-nowrap'>
                                {movie.year}
                            </span>
                                </div>

                                <div className='flex items-center text-white text-base font-normal mb-3'>
                                    <img src={starIcon} alt='' className='me-1  w-[24px] h-[24px] object-cover'/>
                                    <span className='me-2'>
                            امتیاز:
                            </span>
                                    <div className='movie-rate'>{movie.imdb}</div>
                                </div>

                            </div>


                            <div
                                className='text-white text-sm font-light p-3 my-6 text-justify border-t-[1px] border-dashed border-b-[1px]  border-white'>
                                {movie.description}
                            </div>

                            {movie.label && <p className='text-white text-sm font-normal mb-2'>{movie.label}</p>}

                            {
                                movie.movie_type == 1 ?
                                    <div className='movie-download mb-10'>
                                        <Link to={'/download/' + movie.id}
                                           className='border border-custom-yellow2  bg-custom-gray rounded-[10px] flex justify-between align-middle py-2.5 px-3'>
                                            <div className='flex'>
                                                <img src={playIcon2} className='me-2' alt=''/>
                                                <span className='text-white text-sm font-medium '>
                                    مشاهده و دانلود
                                </span>
                                            </div>
                                            <div>
                                                <img src={videoIcon} className='me-2' alt=''/>
                                            </div>
                                        </Link>
                                    </div>
                                    :
                                    <div className="serial-download mb-10">
                                        <Accordion collapseAll>
                                            {movie.download_category.map((season) => (
                                                <Accordion.Panel key={season.id}>
                                                    <Accordion.Title className="custom-accordion-title">
                                                        <div className="flex items-center">
                                                            <img src={serialIcon} alt="" className="me-2"/>
                                                            {season.title}
                                                        </div>
                                                    </Accordion.Title>
                                                    <Accordion.Content>
                                                        {season.children.map((episode) => (
                                                            <Link
                                                                to={'/download/' + movie.id + '/' + episode.id}
                                                                key={episode.id}
                                                                href="#"
                                                                className="bg-custom-gray mb-4 rounded-[10px] p-[14px] flex items-center text-white font-medium text-sm"
                                                            >
                                                                <img src={playIcon3} alt="" className="me-2"/>
                                                                {episode.title}
                                                            </Link>
                                                        ))}
                                                    </Accordion.Content>
                                                </Accordion.Panel>
                                            ))}
                                        </Accordion>
                                    </div>
                            }


                            <div className='actor-list mb-5'>
                                <p className='text-white text-sm font-medium'>بازیگران:</p>
                                <Swiper
                                    dir='rtl'
                                    className="mySwiper4 my-2"
                                    spaceBetween={20}
                                    touchRatio={1}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 3.2,  // نمایش دو اسلاید
                                            spaceBetween: 10,   // ف
                                        },
                                        400: {
                                            slidesPerView: 4.5,  // نمایش دو اسلاید
                                            spaceBetween: 10,   // فاصله بین اسلایدها
                                        },
                                        600: {
                                            slidesPerView: 5.5,  // نمایش چهار اسلاید
                                            spaceBetween: 12,   // فاصله بیشتر
                                        },
                                        800: {
                                            slidesPerView: 8.5,  // نمایش چهار اسلاید
                                            spaceBetween: 15,   // فاصله بیشتر
                                        },

                                    }}
                                >

                                    {movie.actors.map(actor =>
                                        <SwiperSlide key={actor.id}>
                                            <Actor actor={actor}/>
                                        </SwiperSlide>)}

                                </Swiper>
                            </div>


                            <div className='actor-list mb-5 bg-custom-gray2 pt-3'>
                                <p className='text-white text-sm font-medium'>عناوین مشابه</p>
                                <Swiper
                                    dir='rtl'
                                    className="mySwiper2 my-2"
                                    spaceBetween={20}
                                    touchRatio={1}
                                    breakpoints={{
                                        0: {
                                            slidesPerView: 3.2,  // نمایش دو اسلاید
                                            spaceBetween: 10,   // ف
                                        },
                                        400: {
                                            slidesPerView: 4.5,  // نمایش دو اسلاید
                                            spaceBetween: 10,   // فاصله بین اسلایدها
                                        },
                                        600: {
                                            slidesPerView: 5.5,  // نمایش چهار اسلاید
                                            spaceBetween: 12,   // فاصله بیشتر
                                        },
                                        800: {
                                            slidesPerView: 8.5,  // نمایش چهار اسلاید
                                            spaceBetween: 15,   // فاصله بیشتر
                                        },

                                    }}
                                >
                                    {movies &&
                                        movies.map(movieItem =>
                                            <SwiperSlide key={movieItem.id}>
                                                <Movie movie={movieItem}/>
                                            </SwiperSlide>
                                        )
                                    }
                                </Swiper>
                            </div>


                        </div>
                    </section>
                </>
            }


        </>
    )
}

export default MoviePage;