import trashIcon from '../assets/img/svg/trash.svg'
import {Link} from "react-router-dom";

function Bookmark({movie, handleDelete}) {
    return (
        <>
            <div className='custom-movie w-100 '>
                <div className='relative mt-1'>
                    <Link to={'/movie/' + movie.id}>
                        <img className='w-100 mx-auto' src={movie.picture} alt={movie.title}/>
                    </Link>
                    <div className='rate'>{movie.imdb}</div>
                    <div onClick={() => handleDelete(movie.id)}
                         className='delete cursor-pointer bg-custom-black3 rounded-tr-md rounded-bl-md w-[29px] h-[29px]'>
                        <img src={trashIcon} alt='حذف'/>
                    </div>
                </div>
                <Link to={'/movie/' + movie.id}>
                    <p className='text-sm text-white font-medium text-center my-2'>{movie.title}</p>
                </Link>
            </div>
        </>
    )
}

export default Bookmark;