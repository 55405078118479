import {ADD_MOVIE_DATA, REPLACE_MOVIE_DATA} from "./movieActionType";


export  function replaceMovieData(data) {
    return {
        type : REPLACE_MOVIE_DATA,
        payload : data
    }
}
export  function addMovieData(data) {
    return {
        type : ADD_MOVIE_DATA,
        payload : data
    }
}