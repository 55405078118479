import {combineReducers, createStore} from "redux";
import serialReducer from "./serial/serialReducer";
import movieReducer from "./movie/movieReducer";
import bookmarkReducer from "./bookmark/bookmarkReducer";
import resultReducer from "./result/resultReducer";

const rootReducer = combineReducers({
    movies: movieReducer,
    serials: serialReducer,
    bookmarks: bookmarkReducer,
    results: resultReducer,
});

const store = createStore(rootReducer);
export  default store;