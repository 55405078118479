import {ADD_RESULT_DATA, REPLACE_RESULT_DATA} from "./resultActionType";

const initialState = [];


const resultReducer = (state = initialState, action) => {
    switch (action.type){
        case  REPLACE_RESULT_DATA :
            return  action.payload;
        case  ADD_RESULT_DATA :
            return [...state,...action.payload];
        default:
            return  state;
    }
}

export  default  resultReducer;