import {Link} from "react-router-dom";

function Actor({actor}) {
    return (
        <>
            <Link to={'/result?actor_id=' + actor.id}>
                <div className='custom-actor w-100'>
                    <div className='relative mt-1'>
                        <img className='lg:w-[130px] lg:h-[130px] w-[80px] h-[80px] mx-auto rounded-full' src={actor.picture} alt={actor.name}  />
                    </div>
                    <p className='text-sm text-white font-medium text-center my-2'>{actor.name}</p>
                </div>
            </Link>

        </>
    )
}
export  default  Actor;