import downloadIcon from "../assets/img/svg/download.svg";
import playIcon from "../assets/img/svg/play4.svg";
import {useEffect, useState} from "react";
import axios from "axios";
import {baseUrl} from "../helper";
import {useNavigate, useParams} from "react-router-dom";
import {Modal} from "flowbite-react";
import closeIcon from "../assets/img/svg/close-circle.svg";
import ReactPlayer from "react-player";
import arrowRightIcon from "../assets/img/svg/arrow-right.svg";


function DownloadPage() {
    const [movieDownload, setMovieDownload] = useState([]);
    const {movieId, categoryId} = useParams();
    const [openModal, setOpenModal] = useState(false);
    const [playerUrl, setPlayerUrl] = useState('');
    const navigate = useNavigate();

    console.log('categoryId',categoryId)

    useEffect(() => {
        axios.post(`${baseUrl}/api/v1/movieDownload/${movieId}`, {
            'category_id': categoryId
        }).then(res => {
                setMovieDownload(res.data.data)
            })
            .catch(err => {
                console.log('get DownloadPage ====' + err.toString())
            })
    }, [])

    function handleOpen(url) {
        setPlayerUrl(url);
        setOpenModal(true)
    }
    const customThemeModal =
        {
            "content": {
                "inner": "relative flex max-h-[90dvh] flex-col rounded-lg  shadow bg-transparent transition-all duration-1000 ease-in-out  order-modal"
            },
        }

    let previousTitle = '';

    function getBackgroundColor(colorType) {
        switch (colorType) {
            case 1:
                return 'bg-custom-blue';
            case 2:
                return 'bg-green-500';
            case 3:
                return 'bg-custom-yellow';
            case 4:
                return 'bg-custom-red';
            case 5:
                return 'bg-custom-gray';
            default:
                return 'bg-custom-gray';
        }
    }

    return (
        <>

            <Modal position='center' theme={customThemeModal} show={openModal}
                   onClose={() => setOpenModal(false)}>
                <Modal.Body>
                    <div className='font-iransans'>
                        <div className='flex justify-between items-center mb-2'>
                            <p className='text-xl text-bold text-white'>

                            </p>
                            <div>
                                <a className='custom-delete-border-shadow' href='#'
                                   onClick={() => setOpenModal(false)}>
                                    <img src={closeIcon} alt=''/>
                                </a>
                            </div>
                        </div>
                        <ReactPlayer width='100%' className='mx-auto w-full' controls={true}
                                     url={playerUrl}/>
                    </div>
                </Modal.Body>
            </Modal>
            <div className='px-3 mt-[70px]'>

                <section className='header fixed right-0 top-0 left-0 z-20 p-3  bg-[#1D2025]'>
                    <div className='flex items-center'>
                        <a onClick={() => navigate(-1)} className='me-2' href='#'>
                            <img src={arrowRightIcon} alt='' />
                        </a>
                        <p className='text-lg text-white'>
                            بازگشت
                        </p>
                    </div>
                </section>

                {movieDownload.map((movieDownload, index) => {
                    const currentTitle = movieDownload.link_group.title;
                    const shouldDisplayTitle = currentTitle !== previousTitle;

                    previousTitle = currentTitle;
                    return (
                        <>
                            {shouldDisplayTitle &&
                                <p className='text-base text-white font-bold my-3'>
                                    {movieDownload.link_group.title}
                                </p>
                            }
                            <div
                                className={'flex justify-between items-center   p-[13px] rounded-[10px] mb-2 ' + getBackgroundColor(movieDownload.link_group.color_type)}>
                                <a className='cursor-pointer' onClick={() => handleOpen(movieDownload.url)}>
                                    <img src={playIcon} alt='play'/>
                                </a>
                                <p className='text-white text-sm font-medium overflow-nowrap'>
                                    {movieDownload.title} - {movieDownload.description}
                                </p>
                                <a  href={movieDownload.url} download>
                                    <img src={downloadIcon} alt='download'/>
                                </a>
                            </div>
                        </>
                    )
                })}
            </div>


        </>
    );
}

export default DownloadPage;