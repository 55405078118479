import {BrowserRouter, Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import FilterPage from "./pages/FilterPage";
import NotificationPage from "./pages/NotificationPage";
import CalendarPage from "./pages/CalendarPage";
import MoviePage from "./pages/MoviePage";
import DownloadPage from "./pages/DownloadPage";
import {Provider} from "react-redux";
import store from "./redux/store";
import 'swiper/css';
import 'swiper/css/pagination';
import ResultPage from "./pages/ResultPage";

function App() {
  return (
    <>
      <BrowserRouter>
        <Provider store={store}>
            <div className='mt-2 font-iransans'>
                <Routes>
                    <Route path='/' element={<HomePage />} />
                    <Route path='/filter/:movieType' element={<FilterPage />} />
                    <Route path='/search' element={<FilterPage />} />
                    <Route path='/result' element={<ResultPage />} />
                    <Route path='/notification' element={<NotificationPage />} />
                    <Route path='/calendar' element={<CalendarPage />} />
                    <Route path='/movie/:movieId' element={<MoviePage />} />
                    <Route path='/download/:movieId/:categoryId?' element={<DownloadPage />} />
                </Routes>
            </div>
        </Provider>
      </BrowserRouter>
    </>
  );
}

export default App;
