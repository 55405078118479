import playIcon from "../assets/img/svg/play-circle.png";
import {Link} from "react-router-dom";

function Slide({slide}) {
    return (
        <>
            <Link to={'/movie/' + slide.movie.id}>
                <div className='custom-slide relative'>
                    <img src={slide.picture} className='w-100 mx-auto max-h-[350px] object-cover'
                         alt={slide.movie.title_fa}/>
                    <div
                        className='flex justify-center items-center text-white mt-[-80px] absolute bottom-6 right-0 left-0'>
                        <p className='me-2'>{slide.movie.title_fa}</p>
                        <img src={playIcon} alt='play' className='w-[40px] h-[40px]'/>
                    </div>
                </div>
            </Link>
        </>
    )
}

export default Slide;