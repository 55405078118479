import {ADD_SERIAL_DATA, REPLACE_SERIAL_DATA} from "./serialActionType";

const initialState = [];


const serialReducer = (state = initialState,action) => {
    switch (action.type){
        case  REPLACE_SERIAL_DATA :
            return  action.payload;
        case  ADD_SERIAL_DATA :
            return [...state,...action.payload];
        default:
            return  state;
    }
}

export  default  serialReducer;