import notificationIcon from '../../assets/img/svg/notification.svg';
import videoIcon from '../../assets/img/svg/video.svg';
import searchIcon from '../../assets/img/svg/search.svg';
import searchIcon2 from '../../assets/img/svg/search2.svg';
import arrowLeftIcon from '../../assets/img/svg/arrow-left.svg';
// Import Swiper React components
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import required modules
import {Pagination} from 'swiper/modules';
import Slide from "../../components/Slide";
import {baseUrl, getMovie} from "../../helper";
import Movie from "../../components/Movie";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import axios from "axios";


function HomeTab() {
    const [slides, setSlides] = useState([]);
    const [mdata1, setMdata1] = useState(null);
    const [mdata2, setMdata2] = useState(null);
    const [mdata3, setMdata3] = useState(null);
    const [mdata4, setMdata4] = useState(null);
    const [loading, setLoading] = useState(true);

    async function getMovies() {

        const data1 = await getMovie(1, 1);
        const data2 = await getMovie(1, 2);
        const data3 = await getMovie(1, 1, '', 1);
        const data4 = await getMovie(1, 0, '', 0, 1);

        // Set data states after fetching
        setMdata1(data1);
        setMdata2(data2);
        setMdata3(data3);
        setMdata4(data4);

    }


    useEffect(() => {
        setLoading(true)
        axios.get(`${baseUrl}/api/v1/slide`)
            .then(res => {
                setSlides(res.data.data)
                setLoading(false)
            })
            .catch(err => {
                console.log('get slide ====' + err.toString())
                setLoading(false)
            })


        setLoading(true);
        getMovies();


    }, [])


    return (
        <>
            <section className='header fixed right-2 top-0 left-2 z-20 py-2  bg-custom-black'>
                <div className='flex justify-between'>
                    <div className='relative right-2'>
                    <h1 className='text-[#FFB900] font-medium text-[18px] '>Mobo Movie</h1>
                        <div className=' header-title-shadow'/>
                    </div>

                    <div className='flex'>
                        <Link to='/search' className='pe-2'>
                            <img src={searchIcon} alt='search'/>
                        </Link>
                        <Link to='/notification'>
                            <img src={notificationIcon} alt='notification'/>
                            {/*<div*/}
                            {/*    className='bg-[#E40914] w-[12px] h-[12px] text-center text-white rounded-full text-[8px] absolute mt-[-22px]'>2*/}
                            {/*</div>*/}
                        </Link>
                    </div>
                </div>
            </section>
            <div className='px-3 my-[55px]'>
                <section className='slider'>
                    <Swiper
                        pagination={{
                            dynamicBullets: true,
                        }}
                        dir='ltr'
                        modules={[Pagination]}
                        className="mySwiper my-2">
                        {slides.map((slide, index) => (
                            <SwiperSlide key={index}>
                                <Slide slide={slide}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </section>
                <section className='content'>

                    <div className='movie-swipper my-2'>
                        <div className='movie-swipper-header flex justify-between items-center'>
                            <div className='flex justify-between items-center mb-2'>
                                <img src={videoIcon} alt=''/>
                                <p className='text-white text-sm font-medium ms-2'>آخرین فیلم‌ها</p>
                            </div>
                            <div>
                                <a className='text-custom-yellow text-xs flex' href='#'>
                                    همه
                                    <img src={arrowLeftIcon}/>
                                </a>
                            </div>
                        </div>
                        <div className='movie-wipper-content'>
                            <Swiper
                                dir='rtl'
                                className="mySwiper2 my-2"
                                spaceBetween={20}
                                touchRatio={1}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 3.2,  // نمایش دو اسلاید
                                        spaceBetween: 10,   // ف
                                    },
                                    400: {
                                        slidesPerView: 4.5,  // نمایش دو اسلاید
                                        spaceBetween: 10,   // فاصله بین اسلایدها
                                    },
                                    600: {
                                        slidesPerView: 5.5,  // نمایش چهار اسلاید
                                        spaceBetween: 12,   // فاصله بیشتر
                                    },
                                    800: {
                                        slidesPerView: 8.5,  // نمایش چهار اسلاید
                                        spaceBetween: 15,   // فاصله بیشتر
                                    },

                                }}
                            >
                                {mdata1 && mdata1.data.movies.map((movie, index) => (
                                    <SwiperSlide key={index}>
                                        <Movie movie={movie}/>
                                    </SwiperSlide>
                                ))}
                            </Swiper>
                        </div>
                    </div>

                    <div className='movie-swipper my-2'>
                        <div className='movie-swipper-header flex justify-between items-center'>
                            <div className='flex justify-between items-center mb-2'>
                                <img src={videoIcon} alt=''/>
                                <p className='text-white text-sm font-medium ms-2'>آخرین سریال ها</p>
                            </div>
                            <div>
                                <a className='text-custom-yellow text-xs flex' href='#'>
                                    همه
                                    <img src={arrowLeftIcon}/>
                                </a>
                            </div>
                        </div>
                        <div className='movie-wipper-content'>
                            <Swiper
                                dir='rtl'
                                className="mySwiper2 my-2"
                                spaceBetween={20}
                                touchRatio={1}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 3.2,  // نمایش دو اسلاید
                                        spaceBetween: 10,   // ف
                                    },
                                    400: {
                                        slidesPerView: 4.5,  // نمایش دو اسلاید
                                        spaceBetween: 10,   // فاصله بین اسلایدها
                                    },
                                    600: {
                                        slidesPerView: 5.5,  // نمایش چهار اسلاید
                                        spaceBetween: 12,   // فاصله بیشتر
                                    },
                                    800: {
                                        slidesPerView: 8.5,  // نمایش چهار اسلاید
                                        spaceBetween: 15,   // فاصله بیشتر
                                    },

                                }}
                            >
                                {mdata2 && mdata2.data.movies.map((movie, index) => (
                                    <SwiperSlide key={index}>
                                        <Movie movie={movie}/>
                                    </SwiperSlide>
                                ))}

                            </Swiper>
                        </div>
                    </div>


                    <div className='my-6'>
                        <Link to='/search' href='#' className='custom-search-btn flex items-center justify-center '>
                            <img src={searchIcon2} className='me-2'/>
                            <p>
                                چی دوست داری ببینی؟ اینجا رو لمس کن
                            </p>
                        </Link>
                        <div className='custom-search-btn-bg'></div>
                    </div>


                    <div className='movie-swipper my-2'>
                        <div className='movie-swipper-header flex justify-between items-center'>
                            <div className='flex justify-between items-center mb-2'>
                                <img src={videoIcon} alt=''/>
                                <p className='text-white text-sm font-medium ms-2'>فیلم های داغ</p>
                            </div>
                            <div>
                                <a className='text-custom-yellow text-xs flex' href='#'>
                                    همه
                                    <img src={arrowLeftIcon}/>
                                </a>
                            </div>
                        </div>
                        <div className='movie-wipper-content'>
                            <Swiper
                                dir='rtl'
                                className="mySwiper2 my-2"
                                spaceBetween={20}
                                touchRatio={1}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 3.2,  // نمایش دو اسلاید
                                        spaceBetween: 10,   // ف
                                    },
                                    400: {
                                        slidesPerView: 4.5,  // نمایش دو اسلاید
                                        spaceBetween: 10,   // فاصله بین اسلایدها
                                    },
                                    600: {
                                        slidesPerView: 5.5,  // نمایش چهار اسلاید
                                        spaceBetween: 12,   // فاصله بیشتر
                                    },
                                    800: {
                                        slidesPerView: 8.5,  // نمایش چهار اسلاید
                                        spaceBetween: 15,   // فاصله بیشتر
                                    },

                                }}
                            >
                                {mdata3 && mdata3.data.movies.map((movie, index) => (
                                    <SwiperSlide key={index}>
                                        <Movie movie={movie}/>
                                    </SwiperSlide>
                                ))}


                            </Swiper>
                        </div>
                    </div>

                    <div className='movie-swipper my-2'>
                        <div className='movie-swipper-header flex justify-between items-center'>
                            <div className='flex justify-between items-center mb-2'>
                                <img src={videoIcon} alt=''/>
                                <p className='text-white text-sm font-medium ms-2'>تیزر های بروزشده</p>
                            </div>
                            <div>
                                <a className='text-custom-yellow text-xs flex' href='#'>
                                    همه
                                    <img src={arrowLeftIcon}/>
                                </a>
                            </div>
                        </div>
                        <div className='movie-wipper-content'>
                            <Swiper
                                dir='rtl'
                                className="mySwiper2 my-2"
                                spaceBetween={20}
                                touchRatio={1}
                                breakpoints={{
                                    0: {
                                        slidesPerView: 3.2,  // نمایش دو اسلاید
                                        spaceBetween: 10,   // ف
                                    },
                                    400: {
                                        slidesPerView: 4.5,  // نمایش دو اسلاید
                                        spaceBetween: 10,   // فاصله بین اسلایدها
                                    },
                                    600: {
                                        slidesPerView: 5.5,  // نمایش چهار اسلاید
                                        spaceBetween: 12,   // فاصله بیشتر
                                    },
                                    800: {
                                        slidesPerView: 8.5,  // نمایش چهار اسلاید
                                        spaceBetween: 15,   // فاصله بیشتر
                                    },

                                }}
                            >
                                {mdata4 && mdata4.data.movies.map((movie, index) => (
                                    <SwiperSlide key={index}>
                                        <Movie movie={movie}/>
                                    </SwiperSlide>
                                ))}


                            </Swiper>
                        </div>
                    </div>


                </section>
            </div>
        </>
    );
}

export default HomeTab;