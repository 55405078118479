import React from 'react';
import ReactDOM from 'react-dom/client';
import 'react-range-slider-input/dist/style.css';

import './tailwind.css';
import './test.scss';
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
          <App />
  </React.StrictMode>
);
