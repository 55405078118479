import filterIcon from '../../assets/img/svg/filter.svg';
import filterIcon2 from '../../assets/img/svg/filter2.svg';
import closeIcon from '../../assets/img/svg/close-circle.svg';
import {addToBookMarkList, clearBookMarkList, getBookMarkList, getMovie, removeFromBookMarkList} from "../../helper";
import {useEffect, useState} from "react";
import { Label, Modal, Radio, Spinner} from "flowbite-react";
import {useDispatch, useSelector} from "react-redux";
import Bookmark from "../Bookmark";
import {addBookmarkData, replaceBookmarkData} from "../../redux/bookmark/bookmarkAction";
import {Link} from "react-router-dom";

function BookmarkTab() {
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);
    let [page,setPage]  = useState(1);
    const bookmarks = useSelector(state => state.bookmarks);
    const dispatch = useDispatch();
    let [orderType, setOrderType] = useState("1");




    const customTheme =
        {
            "content": {
                "inner": "relative flex max-h-[90dvh] flex-col rounded-lg  shadow bg-transparent transition-all duration-1000 ease-in-out  order-modal"
            },
        }

    function handleOpen() {
        setOpenModal(true)
    }

    function handleChangeOrder(e) {
        setOrderType(e.target.value);
        setOpenModal(false);
    }


    async function fetchMovie(page = 1) {
        const bookmarkData = getBookMarkList().join(',');
        if(bookmarkData.length > 0){
            let data = await getMovie(page, 0,'',0,0,null,null,null,null,bookmarkData,null,null,null,null,null,null,orderType);
            if (page > 1) {
                dispatch(addBookmarkData(data.data.movies))
            } else {
                dispatch(replaceBookmarkData(data.data.movies))
            }
            if (data.data.meta.last_page > page) {
                setHasNextPage(true);
            } else {
                setHasNextPage(false);
            }
            setLoading(false);
        }
    }


    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;

            if (scrollTop + clientHeight >= scrollHeight) {
                if (hasNextPage) {
                    setLoading(true);
                    const nextPage = page + 1;
                    setPage(nextPage);
                    fetchMovie(nextPage);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [page, hasNextPage]);

    useEffect(() => {
        fetchMovie()
    }, [orderType])

    function  handleDelete(id){

       removeFromBookMarkList(id);
        const bookmarkData = getBookMarkList().join(',');
        if(bookmarkData.length == 0){
            dispatch(replaceBookmarkData([]))
        }else{
            fetchMovie();
        }
    }

    return (
        <>

            <section className='header fixed right-0 top-0 left-0 z-20 py-2  bg-custom-black'>
                <div className='flex justify-between'>
                    <div className='relative right-2'>
                    <h1 className='text-[#FFB900] font-medium text-[18px] '>Mobo Movie</h1>
                        <div className=' header-title-shadow'/>
                    </div>
                    <div>
                        <a onClick={() => handleOpen()}
                           className='flex relative z-30 justify-center items-center text-sm text-white bg-custom-red w-[110px] h-[40px] rounded-tr-[50px] rounded-br-[50px]'
                           href='#'>
                            مرتب سازی
                            <img src={filterIcon} alt='' className='ms-0.5'/>
                        </a>
                        <div
                            className='bg-custom-red mt-[-40px] blur-lg w-[110px] h-[40px] rounded-tr-[50px] rounded-br-[50px]'></div>
                    </div>
                </div>
            </section>

            <section className='content'>
                <Modal position='center' theme={customTheme} show={openModal} onClose={() => setOpenModal(false)}>
                    <Modal.Body>
                        <div className='font-iransans'>
                            <div className='flex justify-between items-center mb-10'>
                                <p className='text-xl text-bold text-white'>
                                    مرتب سازی
                                </p>
                                <div>
                                    <a className='custom-delete-border-shadow' href='#'
                                       onClick={() => setOpenModal(false)}>
                                        <img src={closeIcon} alt=''/>
                                    </a>
                                </div>
                            </div>

                            <fieldset className="flex max-w-md flex-col gap-4">
                                <div className="flex items-center gap-2">
                                    <Radio checked={orderType == 1} onChange={(e) => handleChangeOrder(e)}
                                           className='custom-delete-border-shadow text-custom-yellow bg-transparent'
                                           id="d1" name="order_type" value="1"/>
                                    <Label className='custom-sort-label' htmlFor="d1">پیش فرض</Label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Radio checked={orderType == 2} onChange={(e) => handleChangeOrder(e)}
                                           className='custom-delete-border-shadow text-custom-yellow bg-transparent'
                                           id="d2" name="order_type" value="2"/>
                                    <Label className='custom-sort-label' htmlFor="d2">بر اساس سال ساخت</Label>
                                </div>
                                <div className="flex items-center gap-2">
                                    <Radio checked={orderType == 3} onChange={(e) => handleChangeOrder(e)}
                                           className='custom-delete-border-shadow text-custom-yellow bg-transparent'
                                           id="d3" name="order_type" value="3"/>
                                    <Label className='custom-sort-label' htmlFor="d3">بر اساس امتیاز imdb</Label>
                                </div>
                            </fieldset>

                        </div>
                    </Modal.Body>

                </Modal>
                <div className="grid xl:grid-cols-8 lg:grid-cols-6 md:grid-cols-4 grid-cols-3  gap-4">
                    {
                        bookmarks.length > 0 && bookmarks.map(movie => <Bookmark handleDelete={handleDelete}  key={movie.id} movie={movie}/>)
                    }
                </div>
                {loading &&  <div  className="text-center py-5">
                    <Spinner color="warning" aria-label="Center-aligned spinner example" />
                </div>}
            </section>


        </>
    );
}

export default BookmarkTab;