import {Link} from "react-router-dom";

function Movie({movie}) {
    return (
        <>
            <Link to={'/movie/' + movie.id}>
            <div className='custom-movie w-100 '>
                <div className='relative mt-1'>
                    <img className='w-100 mx-auto' src={movie.picture} alt={movie.title}  />
                    <div className='rate'>{movie.imdb}</div>
                </div>
                <p className='text-sm text-white font-medium text-center my-2'>{movie.title}</p>
            </div>
            </Link>
        </>
    )
}
export  default  Movie;