


// Import Swiper styles

import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import filterIcon from "../assets/img/svg/filter.svg";
import {Label, Modal, Radio, Spinner} from "flowbite-react";
import closeIcon from "../assets/img/svg/close-circle.svg";
import Movie from "../components/Movie";
import filterIcon2 from "../assets/img/svg/filter2.svg";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getMovie} from "../helper";
import {addMovieData, replaceMovieData} from "../redux/movie/movieAction";
import {addResultData, replaceResultData} from "../redux/result/resultAction";
import arrowRightIcon from "../assets/img/svg/arrow-right.svg";


function ResultPage() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(false);
    let [page, setPage] = useState(1);
    let [orderType, setOrderType] = useState("1");
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const movies = useSelector(state => state.results);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [manualChange,setManualChange] =  useState(false);


    const customTheme =
        {
            "content": {
                "inner": "relative flex max-h-[90dvh] flex-col rounded-lg  shadow bg-transparent transition-all duration-1000 ease-in-out  order-modal"
            },
        }

    function handleOpen() {
        setOpenModal(true)
    }


    async function fetchMovie(page = 1) {
        setPage(page);
        let movieType = 0;
        let search = '';
        let isHot = 0;
        let isSoon = 0;
        let genreId = null;
        let countryId = null;
        let actorId = null;
        let dubbed = 0;
        let minImdb = null;
        let maxImdb = null;
        let minYear = null;
        let maxYear = null;
        let minAge = null;
        let maxAge = null;


        if (queryParams.has("order_type") && !manualChange) {
           setOrderType(queryParams.get("order_type"));
        }

        if (queryParams.has("movie_type")) {
            movieType = queryParams.get("movie_type")
        }
        if (queryParams.has("search")) {
            search = queryParams.get("search")
        }
        if (queryParams.has("is_hot")) {
            isHot = queryParams.get("is_hot")
        }
        if (queryParams.has("is_soon")) {
            isSoon = queryParams.get("is_soon")
        }
        if (queryParams.has("genre_id")) {
            genreId = queryParams.get("genre_id")
        }
        if (queryParams.has("country_id")) {
            countryId = queryParams.get("country_id")
        }
        if (queryParams.has("actor_id")) {
            actorId = queryParams.get("actor_id")
        }
        if (queryParams.has("dubbed")) {
            dubbed = queryParams.get("dubbed")
        }
        if (queryParams.has("dubbed")) {
            dubbed = queryParams.get("dubbed")
        }
        if (queryParams.has("imdb")) {
            let imdb = queryParams.get("imdb").split(',');
            minImdb = imdb[0];
            maxImdb = imdb[1];
        }
        if(queryParams.has("year")) {
            let year = queryParams.get("year").split(',');
            minYear = year[0];
            maxYear = year[1];
        }
        if(queryParams.has("age")) {
            let age = queryParams.get("age").split(',');
            minAge = age[0];
            maxAge = age[1];
        }
        let data = await getMovie(page, movieType, search, isHot, isSoon, genreId, countryId, actorId, dubbed, null,minAge,maxAge,minImdb,maxImdb,minYear,maxYear,orderType);
       if (page > 1) {
            dispatch(addResultData(data.data.movies))
        } else {
            dispatch(replaceResultData(data.data.movies))
        }
        if (parseInt(data.data.meta.last_page) > parseInt(page)) {
            setHasNextPage(true);
        } else {
            setHasNextPage(false);
        }
        setLoading(false);
    }

    function handleChangeOrder(e) {
        setOrderType(e.target.value);
        setOpenModal(false);
    }


    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;

            if (scrollTop + clientHeight >= scrollHeight) {
                if (hasNextPage) {
                    setLoading(true);
                    const nextPage = page + 1;
                    setPage(nextPage);
                    fetchMovie(nextPage);
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [page, hasNextPage]);

    useEffect(() => {
        fetchMovie()
    }, [orderType])



    return (
        <>
            <div className='px-3 mt-[70px]'>
                <section className='header fixed right-0 top-0 left-0 z-20 p-3  bg-[#1D2025]'>
                    <div className='flex items-center'>
                        <a onClick={() => navigate(-1)} className='me-2' href='#'>
                            <img src={arrowRightIcon} alt='' />
                        </a>
                        <p className='text-lg text-white'>
                            بازگشت
                        </p>
                    </div>
                </section>

                <section className='content'>
                    <Modal position='center' theme={customTheme} show={openModal} onClose={() => setOpenModal(false)}>
                        <Modal.Body>
                            <div className='font-iransans'>
                                <div className='flex justify-between items-center mb-10'>
                                    <p className='text-xl text-bold text-white'>
                                        مرتب سازی
                                    </p>
                                    <div>
                                        <a className='custom-delete-border-shadow' href='#'
                                           onClick={() => setOpenModal(false)}>
                                            <img src={closeIcon} alt=''/>
                                        </a>
                                    </div>
                                </div>

                                <fieldset className="flex max-w-md flex-col gap-4">
                                    <div className="flex items-center gap-2">
                                        <Radio checked={orderType == 1} onChange={(e) => handleChangeOrder(e)}
                                               className='custom-delete-border-shadow text-custom-yellow bg-transparent'
                                               id="d1" name="order_type" value="1"/>
                                        <Label className='custom-sort-label' htmlFor="d1">پیش فرض</Label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <Radio checked={orderType == 2} onChange={(e) => handleChangeOrder(e)}
                                               className='custom-delete-border-shadow text-custom-yellow bg-transparent'
                                               id="d2" name="order_type" value="2"/>
                                        <Label className='custom-sort-label' htmlFor="d2">بر اساس سال ساخت</Label>
                                    </div>
                                    <div className="flex items-center gap-2">
                                        <Radio checked={orderType == 3} onChange={(e) => handleChangeOrder(e)}
                                               className='custom-delete-border-shadow text-custom-yellow bg-transparent'
                                               id="d3" name="order_type" value="3"/>
                                        <Label className='custom-sort-label' htmlFor="d3">بر اساس امتیاز imdb</Label>
                                    </div>
                                </fieldset>
                            </div>
                        </Modal.Body>

                    </Modal>
                    <div className="grid xl:grid-cols-8 lg:grid-cols-6 md:grid-cols-4 grid-cols-3  gap-4">
                        {
                            movies.length > 0 && movies.map(movie => <Movie key={movie.id} movie={movie}/>)
                        }
                    </div>
                    {loading && <div className="text-center py-5">
                        <Spinner color="warning" aria-label="Center-aligned spinner example"/>
                    </div>}
                </section>


                {/*<Link to='/search' className='filter-btn'>*/}
                {/*    <img src={filterIcon2} alt=''/>*/}
                {/*    فیلتر‌ها*/}
                {/*</Link>*/}
            </div>

        </>
    );
}

export default ResultPage;