import {ADD_SERIAL_DATA, REPLACE_SERIAL_DATA} from "./serialActionType";


export  function replaceSerialData(data) {
    return {
        type : REPLACE_SERIAL_DATA,
        payload : data
    }
}
export  function addSerialData(data) {
    return {
        type : ADD_SERIAL_DATA,
        payload : data
    }
}