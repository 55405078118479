import {ADD_BOOKMARK_DATA, REPLACE_BOOKMARK_DATA} from "./bookmarkActionType";


export  function replaceBookmarkData(data) {
    return {
        type : REPLACE_BOOKMARK_DATA,
        payload : data
    }
}
export  function addBookmarkData(data) {
    return {
        type : ADD_BOOKMARK_DATA,
        payload : data
    }
}