import arrowRightIcon from "../assets/img/svg/arrow-right.svg";
import Notification from "../components/Notification";
import {baseUrl} from "../helper";
import {useEffect, useState} from "react";
import axios from "axios";
import {useNavigate} from "react-router-dom";
function NotificationPage() {
    const navigate = useNavigate();
    const [notifications,setNotifications] = useState([])


    useEffect(() => {
        axios.get(`${baseUrl}/api/v1/notification`)
            .then(res => {
                setNotifications(res.data.data)
            })
            .catch(err => {
                console.log('get notification ====' + err.toString())
            })
    }, [])


    return (
        <>
            <section className='header fixed right-0 top-0 left-0 z-20 p-3  bg-[#1D2025]'>
                <div className='flex items-center'>
                    <a onClick={() => navigate(-1)} className='me-2' href='#'>
                        <img src={arrowRightIcon} alt='' />
                    </a>
                    <p className='text-lg text-white'>
                        اطلاع رسانی
                    </p>
                </div>
            </section>
            <div className='px-3 mt-[70px]'>
                    {notifications && notifications.map(notification => <Notification key={notification.id}  notification={notification} />)}
            </div>
        </>
    );
}
export  default  NotificationPage;