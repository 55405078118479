import {ADD_MOVIE_DATA, REPLACE_MOVIE_DATA} from "./movieActionType";

const initialState = [];


const movieReducer = (state = initialState,action) => {
    switch (action.type){
        case  REPLACE_MOVIE_DATA :
            return  action.payload;
        case  ADD_MOVIE_DATA :
            return [...state,...action.payload];
        default:
            return  state;
    }
}

export  default  movieReducer;