import {ADD_RESULT_DATA, REPLACE_RESULT_DATA} from "./resultActionType";


export  function replaceResultData(data) {
    return {
        type : REPLACE_RESULT_DATA,
        payload : data
    }
}
export  function addResultData(data) {
    return {
        type : ADD_RESULT_DATA,
        payload : data
    }
}