


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import {useEffect, useState} from "react";
import BookmarkTab from "../components/tabs/BookmarkTab";
import MovieTab from "../components/tabs/MovieTab";
import SerialTab from "../components/tabs/SerialTab";
import MoreTab from "../components/tabs/MoreTab";
import HomeTab from "../components/tabs/HomeTab";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";


function HomePage() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [currentTab,setCurrentTab] =  useState(0);
    const [manualChange,setManualChange] =  useState(false);
     const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if(!manualChange){
            if(queryParams.has("movie_type") && queryParams.get('movie_type') == 1){
                setCurrentTab(2);
            }
            if(queryParams.has("movie_type") && queryParams.get('movie_type') == 2){
                setCurrentTab(3);
            }else{
                setCurrentTab(1);
            }
        }
    },[queryParams])

    function handleChangeTab(tab){
        setSearchParams([]);
        setCurrentTab(tab);
        setManualChange(true);

    }

    return (
        <>
            <div className='px-3 my-[55px]'>
                {currentTab === 1 && <HomeTab/> }
                {currentTab === 2 && <MovieTab/> }
                {currentTab === 3 && <SerialTab/> }
                {currentTab === 4 && <BookmarkTab/> }
                {currentTab === 5 && <MoreTab/> }
            </div>

            <section className='bottombar bg-custom-black2 z-20 rounded-tl-2xl rounded-tr-2xl fixed right-0 left-0 bottom-0 py-1'>
                <div className="bottombar-container flex justify-around">
                    <div onClick={() => {handleChangeTab(1)}}  className='w-20 cursor-pointer text-center text-sm text-custom-yellow flex justify-center flex-col'>
                        <svg className='d-block mx-auto mb-1' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.31541 2.83998L3.92541 7.03998C3.02541 7.73998 2.29541 9.22998 2.29541 10.36V17.77C2.29541 20.09 4.18541 21.99 6.50541 21.99H18.0854C20.4054 21.99 22.2954 20.09 22.2954 17.78V10.5C22.2954 9.28998 21.4854 7.73998 20.4954 7.04998L14.3154 2.71998C12.9154 1.73998 10.6654 1.78998 9.31541 2.83998Z" stroke={currentTab === 1 ? '#FFB900' : '#FFFF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M12.2954 17.99V14.99" stroke={currentTab === 1 ? '#FFB900' : '#FFFF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        {currentTab === 1 && <span>خانه</span>}
                    </div>
                    <div onClick={() => {handleChangeTab(2)}} className='w-20  cursor-pointer text-center text-sm text-custom-yellow flex justify-center flex-col'>
                        <svg  className='d-block mx-auto mb-1' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.29541 22H15.2954C20.2954 22 22.2954 20 22.2954 15V9C22.2954 4 20.2954 2 15.2954 2H9.29541C4.29541 2 2.29541 4 2.29541 9V15C2.29541 20 4.29541 22 9.29541 22Z" stroke={currentTab === 2 ? '#FFB900' : '#FFFF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M7.18542 2.52002V21.48" stroke={currentTab === 2 ? '#FFB900' : '#FFFF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M17.1854 2.52002V21.48" stroke={currentTab === 2 ? '#FFB900' : '#FFFF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M7.18545 6.96997H2.83545" stroke={currentTab === 2 ? '#FFB900' : '#FFFF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M7.18544 12H2.32544" stroke={currentTab === 2 ? '#FFB900' : '#FFFF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M7.18539 16.97H2.77539" stroke={currentTab === 2 ? '#FFB900' : '#FFFF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M22.1854 6.96997H17.8354" stroke={currentTab === 2 ? '#FFB900' : '#FFFF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M22.1854 12H17.3254" stroke={currentTab === 2 ? '#FFB900' : '#FFFF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M17.2654 12H6.26538" stroke={currentTab === 2 ? '#FFB900' : '#FFFF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M22.1854 16.97H17.7754" stroke={currentTab === 2 ? '#FFB900' : '#FFFF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        {currentTab === 2 && <span>فیلم</span>}
                    </div>
                    <div onClick={() => {handleChangeTab(3)}}  className='w-20  cursor-pointer text-center text-sm text-custom-yellow flex justify-center flex-col'>
                        <svg   className='d-block mx-auto mb-1' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.6654 10.3732L11.9319 14.6334V11.9319C11.9319 11.822 11.9 11.7146 11.8402 11.6224C11.7804 11.5303 11.6952 11.4575 11.5948 11.4128C11.4945 11.3681 11.3834 11.3535 11.2749 11.3707C11.1664 11.3878 11.0652 11.4361 10.9836 11.5095L5.30175 16.6232C5.06851 16.8331 5.04959 17.1924 5.25947 17.4256C5.31273 17.4849 5.37788 17.5323 5.45069 17.5647C5.5235 17.5971 5.60233 17.6138 5.68203 17.6137C5.82236 17.6138 5.95774 17.5619 6.06192 17.4678L10.7955 13.2077V15.9092C10.7955 16.019 10.8273 16.1265 10.8871 16.2186C10.947 16.3107 11.0322 16.3835 11.1325 16.4282C11.2329 16.4729 11.344 16.4875 11.4525 16.4704C11.561 16.4532 11.6622 16.405 11.7438 16.3315L17.4256 11.2179C17.6589 11.008 17.6778 10.6487 17.4678 10.4155C17.2578 10.1821 16.8986 10.1632 16.6654 10.3732Z" stroke={currentTab === 3 ? '#FFB900' : '#FFFF'} />
                            <path d="M20.1705 3.97727H14.4399L16.3109 2.10636C16.5328 1.88449 16.5328 1.52471 16.3109 1.30278C16.089 1.08091 15.7293 1.08091 15.5073 1.30278L13.0211 3.78903L8.30341 0.119714C8.05568 -0.0729566 7.69875 -0.0282974 7.50608 0.219373C7.31341 0.4671 7.35807 0.824089 7.60574 1.0167L11.4122 3.97727H3.125C1.39915 3.97727 0 5.37642 0 7.10227V21.875C0 23.6009 1.39915 25 3.125 25H20.1705C21.8964 25 23.2955 23.6009 23.2955 21.875V7.10227C23.2955 5.37642 21.8964 3.97727 20.1705 3.97727ZM22.1591 21.875C22.1591 22.9733 21.2688 23.8636 20.1705 23.8636H3.125C2.0267 23.8636 1.13636 22.9733 1.13636 21.875V7.10227C1.13636 6.00398 2.0267 5.11364 3.125 5.11364H20.1705C21.2688 5.11364 22.1591 6.00398 22.1591 7.10227V21.875Z" stroke={currentTab === 3 ? '#FFB900' : '#FFFF'} />
                        </svg>
                        {currentTab === 3 && <span>سریال</span>}
                    </div>
                    <div onClick={() => {handleChangeTab(4)}}  className='w-20  cursor-pointer text-center text-sm text-custom-yellow flex justify-center flex-col'>
                        <svg  className='d-block mx-auto mb-1' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M12.62 20.81C12.28 20.93 11.72 20.93 11.38 20.81C8.48 19.82 2 15.69 2 8.68998C2 5.59998 4.49 3.09998 7.56 3.09998C9.38 3.09998 10.99 3.97998 12 5.33998C13.01 3.97998 14.63 3.09998 16.44 3.09998C19.51 3.09998 22 5.59998 22 8.68998C22 15.69 15.52 19.82 12.62 20.81Z" stroke={currentTab === 4 ? '#FFB900' : '#FFFF'} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        {currentTab === 4 && <span>علاقه‌مندی‌ها</span>}
                    </div>
                    <div onClick={() => {setCurrentTab(5);setManualChange(true)}}  className='w-20  cursor-pointer text-center text-sm text-custom-yellow flex justify-center flex-col'>
                        <svg  className='d-block mx-auto mb-1' width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M17 10H19C21 10 22 9 22 7V5C22 3 21 2 19 2H17C15 2 14 3 14 5V7C14 9 15 10 17 10Z" stroke={currentTab === 5 ? '#FFB900' : '#FFFF'} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M5 22H7C9 22 10 21 10 19V17C10 15 9 14 7 14H5C3 14 2 15 2 17V19C2 21 3 22 5 22Z" stroke={currentTab === 5 ? '#FFB900' : '#FFFF'} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6 10C8.20914 10 10 8.20914 10 6C10 3.79086 8.20914 2 6 2C3.79086 2 2 3.79086 2 6C2 8.20914 3.79086 10 6 10Z" stroke={currentTab === 5 ? '#FFB900' : '#FFFF'} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M18 22C20.2091 22 22 20.2091 22 18C22 15.7909 20.2091 14 18 14C15.7909 14 14 15.7909 14 18C14 20.2091 15.7909 22 18 22Z" stroke={currentTab === 5 ? '#FFB900' : '#FFFF'} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        {currentTab === 5 && <span>بیشتر</span>}
                    </div>
                </div>
            </section>
        </>
    );
}

export default HomePage;